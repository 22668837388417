import React, { Suspense, lazy, useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import PartnerArea from "@containers/partner/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import IntroAreaReverse from "@components/introareareverse/layout-1";
import MarketingContent from "@containers/integrations-connectors/marketing-content";
import BoxSection from "@containers/integrations-connectors/software-apps";
import CtaArea from "@containers/cta/layout-07";
import TestimonialArea from "@containers/testimonial/layout-01";
import ContactArea from "@containers/contact-us";
import FaqArea from "@containers/faq/layout-03";
import StickyMenu from "@containers/sticky-menu";
import UseScroll from "@containers/scroll";
const Footer = lazy(() => import("@layout/footer/layout-01"))
const IntegrationsandConnectorsPage = ({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  UseScroll((scrollTop) => {
    if (scrollTop > 20) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
      <Seo title="Translation Connectors for Translation Automation" description="Automate your localization end-to-end with out-of-the-box connectors to content management systems, marketing automation platforms and source code repositories."
      />


      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
          isMobile: isMobile,
        }}
      />
      {
        !isMobile && <StickyMenu data={content["stycky-menu-data"]} setShowRestComponents={setShowRestComponents} showRestComponents={showRestComponents} />
      }
      <main className="site-wrapper-reveal">
        <PageHeader data={content["integrations-and-connectors-page-header-section"]} />
        <PartnerArea data={content["integrations-and-connectors-logo"]} />
        <IntroAreaReverse layout={3} data={content["integrations-intro-body"]} />
        {
          (!isMobile || (isMobile && showRestComponents)) && <MarketingContent data={content["integrations-and-connectors-marketinglist-body"]} />
        }
        {
          showRestComponents && <>
            <BoxSection data={content["integrations-and-connectors-softwareapps-body"]} />
            <MarketingContent layout={2} data={content["integrations-and-connectors-storage-body"]} />
            <BoxSection data={content["integrations-and-connectors-softwareapps-api"]} />
            <FaqArea data={content["integrations-connectors-body"]} />
            <CtaArea data={content["integrations-and-connectors-cta-data"]} />
            <TestimonialArea data={content["testimonial-section"]} />
            <ContactArea Layout={2} />
          </>
        }
      </main>
      {
        showRestComponents && <Suspense fallback={<div></div>}>
          <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
      }
    </Layout>
  );
};

export const query = graphql`
  query IntegrationsandConnectorsPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "integrations-and-connectors" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
    allItSolution(limit: 3) {
      nodes {
        ...ItSolutionThree
      }
    }
  }
`;

IntegrationsandConnectorsPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    allItSolution: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default IntegrationsandConnectorsPage;
